<template>
    <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" v-bind="$attrs">
            {{ dropdownLabel || $t("Reports") }}
        </button>
        <ul class="dropdown-menu">
            <li v-for="ar in dsAppReports_AppReports.data" :key="ar.ID">
                <a class="dropdown-item" :href="makeReportHref(ar, tagReplacementDataObject.current)" target="_blank" rel="noreferrer noopener">{{ ar.Title }}</a>
            </li>
        </ul>
    </div>    
</template>

<script setup>
import { watch } from "vue";

const props = defineProps({
    appId: {
        type: String,
        required: true
    },
    tagReplacementDataObject: {
        type: Object,
        required: true,
    },
    extension: {
        type: String,
        required: false,
        default: "pdf"
    },    
    dropdownLabel: {
        type: String,
        required: false
    }
});

const dsAppReports_AppReports = $getDataObjectById("dsAppReports_AppReports");

watch(() => props.appId, reloadReports, { immediate: true });

async function reloadReports() {
    dsAppReports_AppReports.recordSource.whereClause = `AppID = '${props.appId}'`;
    dsAppReports_AppReports.load();
}

function makeReportHref(appReportRecord, replacementDataRow) {
    return `/nt/file/view/reporting/0/${appReportRecord.ReportID}/${appReportRecord.ReportID}.${props.extension}?Filter=${createFilter(appReportRecord, replacementDataRow)}`;
}

function createFilter(appReportRecord, replacementDataRow) {
    let filterTemplate = appReportRecord.Filter;
    if (!filterTemplate) {
        return "1=1"; // If there is no filter template set on the AppReports record, then use default.
    }

    props.tagReplacementDataObject.fields.fields.forEach(f => {
        filterTemplate = filterTemplate.replaceAll("{{" + f.name + "}}", replacementDataRow[f.name] ?? "");
    });

    return filterTemplate;
}
</script>